export function effectParallax() {
  const image = document.querySelector('#parallax')
  const position = window.pageXOffset || document.documentElement.scrollTop

  if (image) {
    image.style.top = `-${position * 0.09}px`

    window.onscroll = function () {
      const position = window.pageXOffset || document.documentElement.scrollTop

      image.style.top = `-${position * 0.09}px`
    }
  }
}
