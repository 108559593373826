export default function responsiveHeader() {
  const menu = document.querySelector('#menu')
  const bars = document.querySelector('#bars')
  const xmark = document.querySelector('#xmark')
  const nav = document.querySelector('nav')
  const bgMenu = document.querySelector('.bg-menu')
  let conmutador = true

  menu.addEventListener('click', () => {
    if (conmutador) {
      bars.style.display = 'none'
      xmark.style.display = 'block'
      nav.style.right = '0'
      bgMenu.style.display = 'block'
      return (conmutador = false)
    }

    if (!conmutador) {
      bars.style.display = 'block'
      xmark.style.display = 'none'
      nav.style.right = '-100%'
      bgMenu.style.display = 'none'
      return (conmutador = true)
    }
  })

  bgMenu.addEventListener('click', () => {
    if (conmutador) {
      bars.style.display = 'none'
      xmark.style.display = 'block'
      nav.style.right = '0'
      bgMenu.style.display = 'block'
      return (conmutador = false)
    }

    if (!conmutador) {
      bars.style.display = 'block'
      xmark.style.display = 'none'
      nav.style.right = '-100%'
      bgMenu.style.display = 'none'
      return (conmutador = true)
    }
  })

  window.addEventListener('load', () => {
    setTimeout(() => {
      const messageContainer = document.querySelector(
        '#hubspot-messages-iframe-container'
      )

      if (messageContainer) {
        var item = messageContainer.querySelector('.hs-shadow-container')
      }

      if (item !== undefined) {
        if (item.classList.contains('active')) {
          return console.log(item)
        } else {
          messageContainer.style.width = '0px'
        }
      }
    }, 1500)
  })
}
