export default function modalContact() {
  const modal = document.querySelector('.modal-extern')
  const btnClose = document.querySelector('.modal-extern .modal-intern span')
  let isDeployedModal = false

  document.addEventListener('DOMContentLoaded', () => {
    const altura = document.body.scrollHeight

    window.addEventListener('scroll', () => {
      const positionY = window.pageXOffset || document.documentElement.scrollTop

      if (positionY > altura / 2 && !isDeployedModal && modal) {
        isDeployedModal = true
        modal.classList.add('-active-modal')
      }
    })
  })

  if (btnClose) {
    btnClose.addEventListener('click', () => {
      modal.classList.remove('-active-modal')
    })
  }
}
