import Swiper from 'swiper/swiper-bundle.min.js'

export default function swiperClients() {
  // Initialize Swiper
  // eslint-disable-next-line no-unused-vars
  var swiper1 = new Swiper('.box-img', {
    slidesPerView: 1,
    loop: true,
    autoplay: {
      delay: 3250,
      disableOnInteraction: false
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    }
  })

  // eslint-disable-next-line no-unused-vars
  var swiper2 = new Swiper('.box-testimonials', {
    slidesPerView: 1,
    loop: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
      dynamicBullets: true
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    }
  })

  // eslint-disable-next-line no-unused-vars
  var swiper3 = new Swiper('.mySwiper', {
    slidesPerView: 1,
    breakpoints: {
      575: {
        slidesPerView: 2
      }
    },
    grid: {
      rows: 2
    },
    spaceBetween: 30,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    }
  })

  // eslint-disable-next-line no-unused-vars
  var swiper4 = new Swiper('.myCarouselImages', {
    slidesPerView: 1,
    spaceBetween: 5,
    loop: true,
    breakpoints: {
      1025: {
        slidesPerView: 3,
        spaceBetween: 25
      },
      910: {
        slidesPerView: 2.5,
        spaceBetween: 15
      },
      768: {
        slidesPerView: 2.3,
        spaceBetween: 15
      },
      525: {
        slidesPerView: 1.5,
        spaceBetween: 30
      }
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    }
  })

  // eslint-disable-next-line no-unused-vars
  var swiper5 = new Swiper('.service-testimonials', {
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    }
  })

  // eslint-disable-next-line no-unused-vars
  var swiper6 = new Swiper('.service-individual-testimonials', {
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    }
  })
}
