export default function popUpCatalog() {
  const iconWrapper = document.querySelectorAll('.icon-wrapper')
  const popUp = document.querySelector('.pop-up-catalog')
  const containerIntern = document.querySelector(
    '.pop-up-catalog .container-intern'
  )

  if (iconWrapper.length > 0) {
    iconWrapper.forEach(element => {
      element.addEventListener('click', () => {
        const link = element.firstElementChild.href
        if (link !== undefined) {
          popUp.classList.add('-active-popup')
          console.log('link')
          console.log(link)
          const iframeCustom = `<iframe src="${link}" frameborder="0" title="Types of designs"></iframe>`
          containerIntern.innerHTML = iframeCustom
        }
      })
    })
  }

  const closeIframe = document.querySelector('#close-iframe')

  if (closeIframe) {
    closeIframe.addEventListener('click', () => {
      popUp.classList.remove('-active-popup')
    })
  }
}
