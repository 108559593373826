export default function expandImage() {
  const imageList = document.querySelectorAll('.img-project figure img')
  const imageListOurServices = document.querySelectorAll('.project-item img')
  const btnClose = document.querySelector('#close-img')
  const expandImageContainer = document.querySelector('.expand-image')
  const expandImage = document.querySelector('.expand-image figure img')

  imageList.forEach((element, index) => {
    element.addEventListener('click', () => {
      expandImageContainer.classList.add('-active-img')
      expandImage.src = element.src
    })
  })

  imageListOurServices.forEach((element, index) => {
    element.addEventListener('click', () => {
      expandImageContainer.classList.add('-active-img')
      expandImage.src = element.src
    })
  })

  if (btnClose) {
    btnClose.addEventListener('click', () => {
      if (expandImageContainer.classList[1] === '-active-img') {
        expandImageContainer.classList.remove('-active-img')
      }
    })
  }
}
